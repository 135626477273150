import Home from "./views/Home";
import ReactGA from "react-ga";

ReactGA.initialize("G-EVP62XMFGG");

function App() {
	return (
		<main className="overflow-hidden">
			<Home />
		</main>
	);
}

export default App;
