import { useState } from "react";
import { useMobile } from "../../utils/useMobile";
import { faCircleNotch, faVideo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactGA from "react-ga";
import { cc } from "../../utils/cc";

const Video: React.FC = () => {
	const isMobile = useMobile();
	const [isLoaded, setLoaded] = useState(false);
	const [isLoading, setLoading] = useState(false);
	const [index, setIndex] = useState(0);
	const handleLoad = () => {
		if (index === 3) return;
		setLoading(true);
		const timer = setTimeout(() => {
			setLoading(false);
			setLoaded(true);
			setIndex(index + 1);
			console.log(index);
			let element = document.getElementById(`vid-${index}`);
			console.log(element);
			element?.scrollIntoView({
				behavior: "auto",
				block: "center",
				inline: "center"
			});
		}, 1000);
		return () => clearTimeout(timer);
	};
	return (
		<div className="w-full h-full items-center relative flex justify-center flex-col">
			<div className="rounded shadow-image overflow-hidden flex mb-12">
				<iframe
					style={isMobile ? { height: "60vh", width: "98vw" } : { height: "50vh", width: "59vw" }}
					src="https://www.youtube-nocookie.com/embed/xYsNgAB_mRY"
					title="Inaki Bascaran Missing Search Video"
					frameBorder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
					allowFullScreen
				/>
			</div>
			{isLoading && index === 0 && (
				<div className="text-5xl text-yellowHover mb-12">
					<FontAwesomeIcon icon={faCircleNotch} className="fa-spin" />
				</div>
			)}
			{isLoaded && index >= 1 && (
				<div className="rounded shadow-image overflow-hidden flex mb-12" id="vid-0">
					<iframe
						style={isMobile ? { height: "60vh", width: "98vw" } : { height: "50vh", width: "59vw" }}
						src="https://www.youtube-nocookie.com/embed/QIiByOrdi9A?autoplay=true"
						title="Inaki Bascaran Missing Search Video"
						frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
					/>
				</div>
			)}
			{isLoading && index === 1 && (
				<div className="text-5xl text-yellowHover mb-12">
					<FontAwesomeIcon icon={faCircleNotch} className="fa-spin" />
				</div>
			)}
			{isLoaded && index >= 2 && (
				<div className="rounded shadow-image overflow-hidden flex mb-12" id="vid-1">
					<iframe
						style={isMobile ? { height: "60vh", width: "98vw" } : { height: "50vh", width: "59vw" }}
						src="https://www.youtube-nocookie.com/embed/GFcbiGvwHdo?autoplay=true"
						title="Inaki Bascaran Missing Search Video"
						frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
					/>
				</div>
			)}
			{isLoading && index === 2 && (
				<div className="text-5xl text-yellowHover mb-12">
					<FontAwesomeIcon icon={faCircleNotch} className="fa-spin" />
				</div>
			)}
			{isLoaded && index === 3 && (
				<div className="rounded shadow-image overflow-hidden flex mb-12" id="vid-2">
					<iframe
						style={isMobile ? { height: "60vh", width: "98vw" } : { height: "50vh", width: "59vw" }}
						src="https://www.youtube-nocookie.com/embed/_ph92Ebaugs?autoplay=true"
						title="Inaki Bascaran Missing Search Video"
						frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
					/>
				</div>
			)}
			{index >= 0 && index <= 2 && !isLoading && (
				<button
					className={cc(isMobile ? "w-full" : "", "rounded outline-border-yellow py-4 px-8 text-lg font-bold hover:bg-yellowHover hover:outline-border-yellowHover transition-all bg-yellow shadow active:scale-90 hover:shadow-button")}
					onClick={() => {
						handleLoad();
						ReactGA.event({ category: "view", action: "click", label: `video_${index + 1}` });
					}}>
					<FontAwesomeIcon icon={faVideo} className="mr-2 " />
					Load more videos
				</button>
			)}
		</div>
	);
};

export default Video;
