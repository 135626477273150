import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullhorn, faHeart } from "@fortawesome/free-solid-svg-icons";
import ReactGA from "react-ga";
import { useEffect, useState } from "react";
import Video from "../components/Video";

const Home = () => {
	const [countdown, setCountdown] = useState(10);
	//const [isLoading, setLoading] = useState(false);
	const [loaded, setLoaded] = useState(true);
	const [modalVisible, setModalVisible] = useState<boolean>(true);

	useEffect(() => {
		ReactGA.pageview(window.location.pathname);
		handleRedirect();
	});

	const handleRedirect = () => {
		if (loaded) return;
		document.body.classList.add("overflow-hidden");
		window.scrollTo(0, 0);
		if (countdown > 0) return setTimeout(() => setCountdown(countdown - 1), 1000);
		if (countdown === 0 && !loaded) return handleLoading();
	};

	const handleLoading = () => {
		//setLoading(true);
		const timer = setTimeout(() => {
			setLoaded(true);
			window.location.href = "https://vantius.com/inaki-bascaran/";
		}, 2500);
		return () => clearTimeout(timer);
	};

	return (
		<div className="max-w-screen overflow-hidden relative">
			{modalVisible && (
				<div className="h-screen w-screen absolute z-40 flex" style={{ background: "rgba(0, 0, 0, 0.6)", height: "calc(100vh - 108px)", top: "108px" }}>
					<div className="flex justify-center w-full h-full items-center content-center">
						<div className="bg-white-500 lg:px-12 lg:py-16 px-4 py-8 rounded shadow-image text-center flex flex-col gap-4 items-center">
							<FontAwesomeIcon icon={faHeart} className="pb-8 text-5xl text-error h-full w-full" />
							<div className="text-lg font-bold pb-4">Thank you.</div>
							<div>Iñaki's body has been recovered from the Chicago River.</div>
							<div>Thank you to everyone who helped with our search efforts.</div>
							<div
								className="underline font-bold pb-4 cursor-pointer"
								onClick={() => {
									document.getElementById("wake")!.scrollIntoView({ behavior: "smooth" });
									ReactGA.event({ category: "wake", action: "click", label: "wake" });
								}}>
								Click here for information regarding Iñaki's wake.
							</div>
							<button
								className="w-full rounded outline-border-yellow py-4 px-8 text-lg font-bold hover:bg-yellowHover hover:outline-border-yellowHover transition-all bg-yellow shadow active:scale-90 hover:shadow-button"
								onClick={() => setModalVisible(false)}>
								Close
							</button>
						</div>
					</div>
				</div>
			)}
			<div
				className="bg-error lg:p-8 p-4 text-white flex justify-between w-full items-center z-50 cursor-pointer"
				style={{ height: "108px", color: "white" }}
				onClick={() => {
					document.getElementById("description")!.scrollIntoView({ behavior: "smooth" });
					ReactGA.event({ category: "announcement", action: "click", label: "announcement" });
				}}>
				<div className="inline-block text-xl ">
					<div className="font-bold inline-block">
						<FontAwesomeIcon icon={faBullhorn} className="mr-2" />
						Announcement:
					</div>
					<h3 className="lg:ml-1 inline-block underline">Click to view the latest information about Iñaki.</h3>
					<div className="text-sm">Last updated 11/06/2021 5:30 PM CST</div>
				</div>
			</div>
			<div className="relative w-screen relative">
				<div className="flex items-center content-center justify-center bg-yellow" style={{ height: "calc(100vh - 108px)" }}>
					<div className="h-full lg:rounded overflow-hidden lg:mt-2">
						<img className="w-full h-full object-contain object-center" src="./inaki.jpg" alt="Missing person Inaki Bascaran" style={{ maxHeight: "calc(100vh - 112px)" }} />
					</div>
				</div>
				{/* <div className="w-full h-full lg:px-8 px-2 py-16 flex items-center relative flex-col bg-gray">
					<h2 className="font-bold text-5xl text-center">Help Find Him</h2>
					<div className={cc(isMobile ? "w-full" : "", "flex lg:flex-row flex-col justify-center mt-12 items-center w-full")}>
						<a href="./inaki_missing_poster.pdf" download className={cc(isMobile ? "w-full" : "", "lg:mr-2 mb-4 lg:mb-0")}>
							<button
								className={cc(isMobile ? "w-full" : "", "rounded outline-border-yellow py-4 px-8 text-lg font-bold hover:bg-yellowHover hover:outline-border-yellowHover transition-all bg-yellow shadow active:scale-90 hover:shadow-button")}
								onClick={() => ReactGA.event({ category: "download", action: "click", label: "download_pdf" })}>
								<FontAwesomeIcon icon={faFileDownload} className="mr-1" /> Download Flyer PDF
							</button>
						</a>
						<a href="./inaki.jpg" download className={cc(isMobile ? "w-full" : "", "lg:ml-2")}>
							<button
								className={cc(isMobile ? "w-full" : "", "rounded outline-border-yellow py-4 px-8 text-lg font-bold hover:bg-yellowHover hover:outline-border-yellowHover transition-all bg-yellow shadow active:scale-90 hover:shadow-button")}
								onClick={() => ReactGA.event({ category: "download", action: "click", label: "download_image" })}>
								<FontAwesomeIcon icon={faFileImage} className="mr-1" /> Download Flyer Image
							</button>
						</a>
					</div>
				</div> */}
				<div className="w-full h-full lg:px-8 px-2 py-16 flex items-center relative flex-col" id="description">
					<h2 className="font-bold text-5xl text-center">Donate Now</h2>
					<div className="h-full w-full mt-12 flex items-center justify-center">
						<ReactGA.OutboundLink className="cursor-pointer w-full flex" eventLabel="gofundme" to="https://www.gofundme.com/f/please-help-us-find-inaki" target="_blank">
							<div className="gfm-embed pointer-events-none w-full" data-url="https://www.gofundme.com/f/please-help-us-find-inaki/widget/medium/" />
						</ReactGA.OutboundLink>
					</div>
				</div>
				<div className="w-full h-full lg:px-8 px-2 py-16 flex items-center relative flex-col">
					<h2 className="font-bold text-5xl text-center">What We Know</h2>
					<div className="" style={{ maxHeight: "30%" }}>
						<div className="shadow-image rounded overflow-hidden mt-12">
							<img className="w-full object-cover object-center" src="./inaki_celeste.jpg" alt="Missing person Inaki Bascaran last seen at Celeste wearing a gray shirt and blue pants." />
						</div>
					</div>
					<ol className="flex w-full h-full flex-col mt-12">
						<li className="lg:px-8 mb-8 text-center text-xl">
							1. He was last seen at Celeste in River North at 11:39 PM. <br />
							He was wearing a gray long sleeve shirt, blue pants, and black vans with a white stripe, and an Apple Watch.
						</li>
						<li className="lg:px-8 mb-8 text-center text-xl">
							2. Last contact with Iñaki was at 12:04am on 10/31/2021. After leaving Celeste, Iñaki FaceTimed a friend and stated that he was outside of a Walgreens <br />
							<ReactGA.OutboundLink className="cursor-pointer underline" eventLabel="walgreens_map" to="https://www.google.com/maps/search/walgreens/@41.8909035,-87.6250169,16z" target="_blank">
								This could be any Walgreens within walking distance of Celeste.
							</ReactGA.OutboundLink>
						</li>
						<li className="lg:px-8 text-center mb-8 text-xl">3. He then said he was on his way back to his apartment in Wrigleyville. It is approximately a 4.5 mile walk from Celeste to Wrigleyville.</li>
						<li className="lg:px-8 text-center mb-8 text-xl">4. His iPhone and Apple Watch are dead. His last Uber was to Celeste. There have been 0 charges to his credit, debit, or public transit (Ventra) cards.</li>
						<li className="lg:px-8 text-center mb-8 text-xl">5. Iñaki's body was recovered from the Chicago River on 11/5/2021 around 4:53 PM CST near the 1000 block of South Wells Street.</li>
					</ol>
				</div>
				<div className="w-full min-h-screen lg:px-8 px-2 py-16 items-center relative flex justify-center bg-black">
					<Video />
				</div>
				<div className="w-full h-full lg:px-8 px-2 py-16 flex items-center relative flex-col bg-gray" id="wake">
					<h2 className="font-bold text-5xl text-center">Iñaki's Wake</h2>
					<div className="flex w-full h-full flex-col mt-12">
						<p className="lg:px-8 mb-12 text-center text-xl">Please join in celebrating Iñaki's life. The wake will conclude with a Mass.</p>
						<p className="lg:px-8 mb-2 text-center text-xl">Wednesday, November 10th, 2021</p>
						<p className="lg:px-8 mb-8 text-center text-xl">3:00 - 7:00 PM CST</p>
						<p className="lg:px-8 mb-2 text-center text-xl font-bold">Our Lady of Perpetual Help</p>
						<ReactGA.OutboundLink className="lg:px-8 mb-2 text-center text-xl cursor-pointer" eventLabel="wake_map" to="https://goo.gl/maps/N3WK6QepiufwruvB6" target="_blank">
							<p className="inline-block underline">1123 Church Street, Glenview, Illinois 60025</p>
						</ReactGA.OutboundLink>
					</div>
				</div>
				<div className="w-full h-full lg:px-8 px-2 py-16 flex items-center relative flex-col">
					<h2 className="font-bold text-5xl text-center">Important Links</h2>
					<div className="flex justify-between w-full h-full lg:flex-row flex-col">
						<div className="lg:w-1/4 w-full flex items-center flex-col lg:px-8 mt-12">
							<div className="font-medium text-3xl mb-2 cursor-pointer underline">
								<ReactGA.OutboundLink eventLabel="chicago_police" to="https://home.chicagopolice.org/missing-person-inaki-bascaran-23/" target="_blank">
									Chicago Police
								</ReactGA.OutboundLink>
							</div>
						</div>
						<div className="lg:w-1/4 w-full flex items-center flex-col lg:px-8 mt-12">
							<div className="font-medium text-3xl mb-2 cursor-pointer">
								<ReactGA.OutboundLink eventLabel="instagram" to="https://www.instagram.com/bringinakihome/" target="_blank">
									<p className="inline-block">@</p>
									<p className="inline-block underline">BringInakiHome</p>
								</ReactGA.OutboundLink>
							</div>
						</div>
						<div className="lg:w-1/4 w-full flex items-center flex-col lg:px-8 mt-12">
							<div className="font-medium text-3xl mb-2 cursor-pointer underline">
								<ReactGA.OutboundLink eventLabel="reddit" to="https://www.reddit.com/r/chicago/comments/qk61up/missing_person_alert_please_contact_chicago/" target="_blank">
									Reddit Thread
								</ReactGA.OutboundLink>
							</div>
						</div>
						<div className="lg:w-1/4 w-full flex items-center flex-col lg:px-8 mt-12">
							<div className="font-medium text-3xl mb-2 cursor-pointer underline">
								<ReactGA.OutboundLink eventLabel="map" to="https://vantius.com/inaki-bascaran/" target="_blank">
									Vantius
								</ReactGA.OutboundLink>
							</div>
						</div>
					</div>
				</div>
				<div className="w-full h-full px-2 py-16 flex items-center relative flex-col bg-yellow">
					<h2 className="text-5xl font-bold">
						<ReactGA.OutboundLink eventLabel="chicago_police" to="https://home.chicagopolice.org/missing-person-inaki-bascaran-23/" target="_blank" className="underline">
							Contact Chicago Police with any information.
						</ReactGA.OutboundLink>
					</h2>
				</div>
			</div>
		</div>
	);
};
export default Home;
